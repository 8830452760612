import React from 'react'
import { Link } from 'gatsby'
import LogoImageDesktop from '../images/logo-vertical.svg'
import LogoImageMobile from '../images/logo-horizontal.svg'
import * as headerStyles from '../styles/modules/header.module.scss'

const Logo = () => (
  <Link className={headerStyles.logoLink} to="/">
    <img
      className={headerStyles.logoHorizontal}
      alt="Redfin Solutions logo"
      src={LogoImageMobile}
    />
    <img
      className={headerStyles.logoVertical}
      alt="Redfin Solutions logo"
      src={LogoImageDesktop}
    />
  </Link>
)

export default Logo
