import React from 'react'
import { Link } from 'gatsby'
import * as headerStyles from '../styles/modules/header.module.scss'

const links = [
  { to: '/about', text: 'About' },
  { to: '/portfolio', text: 'Portfolio' },
  { to: '/blog', text: 'Blog' },
]

const Nav = () => (
  <nav className={headerStyles.nav} id="main-nav" role="navigation" aria-label="Main">
    {links.map(({ to, text }, index) => (
      <Link className={headerStyles.navLink} to={to} key={index}>
        {text}
      </Link>
    ))}
  </nav>
)

export default Nav
