import React from 'react'
import { Link } from 'gatsby'
import Social from './social'
import FooterCall from '../images/footer-call.svg'
import FooterEmail from '../images/footer-email.svg'
import * as footerStyles from '../styles/modules/footer.module.scss'

const Footer = () => (
  <footer className={footerStyles.footer}>
    <div>
      <p>Redfin Solutions, LLC</p>
      <p>Portland, ME</p>
    </div>
    <div>
      <p>
        <a href="tel:1-908-437-8725" target="_blank" rel="noopener noreferrer">
          <img alt="phone icon" src={FooterCall} className={footerStyles.icon} />
          908-437-8725
          <br />
          <span className={footerStyles.phoneAlias}>908-4DRUPAL</span>
        </a>
      </p>
      <p>
        <a
          className="footer-link-email"
          href="mailto:411@redfinsolutions.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="email icon" src={FooterEmail} className={footerStyles.icon} />
          411@redfinsolutions.com
        </a>
      </p>
    </div>
    <nav className={footerStyles.links} id="footer-nav" aria-label="Footer">
      <Link to="/accessibility">accessibility</Link>
      <Link to="/privacy">privacy</Link>
      <Link to="/terms">terms</Link>
    </nav>
    <Social small className={footerStyles.social} />
  </footer>
)

export default Footer
