import React from 'react'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'
import '@reach/skip-nav/styles.css'
import Header from './header'
import Footer from './footer'
import ClipCurves from './clip-curves'
import * as layoutStyles from '../styles/modules/layout.module.scss'
import '../styles/styles.scss'

const Layout = ({ children }) => (
  <>
    <SkipNavLink style={{ zIndex: 999 }} />
    <div className={layoutStyles.layout}>
      <Header />
      <SkipNavContent />
      <main>{children}</main>
      <Footer />
    </div>
    <ClipCurves />
  </>
)

export default Layout
