import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import facebook from '../images/facebook.svg'
import twitter from '../images/twitter.svg'
import linkedin from '../images/linkedin.svg'
import instagram from '../images/instagram.svg'
import * as socialStyles from '../styles/modules/social.module.scss'

const Social = ({ small, className }) => (
  <div
    className={`${className || ''} ${socialStyles.social} ${
      small ? socialStyles.small : ''
    }`}
  >
    <OutboundLink
      className={socialStyles.facebook}
      href="https://facebook.com/redfinsolutions"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img alt="facebook" src={facebook} />
    </OutboundLink>
    <OutboundLink
      className={socialStyles.twitter}
      href="https://twitter.com/redfinsolutions"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img alt="twitter" src={twitter} />
    </OutboundLink>
    <OutboundLink
      className={socialStyles.linkedin}
      href="https://www.linkedin.com/company/redfin-solutions-llc/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img alt="linked in" src={linkedin} />
    </OutboundLink>
    <OutboundLink
      className={socialStyles.instagram}
      href="https://instagram.com/redfinsolutions"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img alt="instagram" src={instagram} />
    </OutboundLink>
  </div>
)

export default Social
