import React from 'react'
import { Link } from 'gatsby'
import * as buttonStyles from '../styles/modules/button.module.scss'

const Button = ({ link, text, big, gradient, marginNone, medium, shadow }) => (
  <Link
    className={[
      buttonStyles.button,
      big ? buttonStyles.big : '',
      gradient ? buttonStyles.gradient : '',
      medium ? buttonStyles.medium : '',
      shadow ? buttonStyles.shadow : '',
      marginNone ? buttonStyles.marginNone : '',
    ].join(' ')}
    to={link}
  >
    {text}
  </Link>
)

export default Button
