import React from 'react'
import Logo from './logo'
import Button from './button'
import Nav from './nav'
import * as headerStyles from '../styles/modules/header.module.scss'

const Header = () => (
  <header className={headerStyles.header}>
    <Logo />
    <Button gradient text="Contact" link="/contact" />
    <Nav />
  </header>
)

export default Header
