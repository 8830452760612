import React from 'react'

const ClipCurves = () => (
  <>
    <svg width="0" height="0">
      <defs>
        <clipPath id="topCurve" clipPathUnits="objectBoundingBox">
          <path
            d="M 0,1
            L 0,0
            C 0 0, 1.6 0, 1 1
            L 0,1
            Z"
          />
        </clipPath>
      </defs>
    </svg>
    <svg width="0" height="0">
      <defs>
        <clipPath id="bottomCurve" clipPathUnits="objectBoundingBox">
          <path
            d="M 0,1
            L 0,0
            L 1,0
            L 1,1
            C .7 .85, 0 .8, 0 1
            Z"
          />
        </clipPath>
      </defs>
    </svg>
    <svg width="0" height="0">
      <defs>
        <clipPath id="rightCurve" clipPathUnits="objectBoundingBox">
          <path
            d="M 0,1
            L 0,0
            L 1,0
            C .8 .15, 1 1, 1 1
            L 0,1
            Z"
          />
        </clipPath>
      </defs>
    </svg>
    <svg width="0" height="0">
      <defs>
        <clipPath id="leftCurve" clipPathUnits="objectBoundingBox">
          <path
            d="M 0,1
            C .2 .15, 0 0, 0 0
            L 1,0
            L 1,1
            L 0,1
            Z"
          />
        </clipPath>
      </defs>
    </svg>
    <svg width="0" height="0">
      <defs>
        <clipPath id="horizontalWave" clipPathUnits="objectBoundingBox">
          <path
            d="M 0,.9
            L 0,0
            L 1,0
            L 1,.9
            C .7 .6, .4 1, 0 .9
            Z"
          />
        </clipPath>
      </defs>
    </svg>
    <svg width="0" height="0">
      <defs>
        <clipPath id="verticalWave" clipPathUnits="objectBoundingBox">
          <path
            d="M 0,1
            L 0,0 
            L .9,0 
            C .75 .5, 1 .5, .9 1 
            L 0,1 
            Z"
          />
        </clipPath>
      </defs>
    </svg>
  </>
)

export default ClipCurves
